<template class="w-full h-full " >
  <img src="@/assets/images/404.jpg" alt="">
</template>

<script>
export default {

}
</script>

<style>

</style>